import React, { useEffect } from "react";
import { backendurl } from "../../Utils/BackendURL";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import "./Otp.css";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Otp() {
  toast("OTP has been sent to your mail id, please enter it here", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to access user data
      const decodedToken = jwt_decode(token);
      const email = decodedToken.user.email;

      if (email) {
        console.log("Sign In Email:", email);
        navigate("/dashboard");
      }
    }
  }, [navigate]);

  const initialValues = {
    otp: ["", "", "", "", "", ""],
  };

  const handleSubmit = (values) => {
    const otp = values.otp.join("");
    var data = { otp: otp, email: email };
    axios
      .post(backendurl + "otp", data)
      .then((res) => {
        if (res.data.status === "correct") {
          console.log("OTP is correct");
          const token = res.data.token;
          localStorage.setItem("token", token);
          navigate("/dashboard");
        } else if (res.data.status === "incorrect") {
          alert("Entered OTP is wrong, please recheck OTP");
        } else if (res.data.status === "email error") {
          alert("Wrong Email, please contact connect@stackzero.in");
        } else {
          alert("Error in autenticating, please contact connect@stackzero.in");
        }
      })
      .catch((err) => {
        console.log("Error in processeing request", err);
      });
  };

  const handleInputFocus = (currentInput, nextInput) => {
    if (currentInput.value !== "" && nextInput) {
      nextInput.focus();
    }
  };

  return (
    <div className="otp_container justify-content-center align-items-center">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Container className="w-100">
        <Card className="text-center p-4">
          <h3>
            Please enter the One-Time Password sent to verify your account
          </h3>
          <p>A One-Time password has sent to {email}</p>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="otp_form">
                <div className="form-group otp-input-container">
                  {Array.from({ length: 6 }, (_, index) => (
                    <Field
                      key={index}
                      type="number"
                      name={`otp[${index}]`}
                      className="form-control otp-input"
                      min="0"
                      max="9"
                      maxLength="1"
                      onInput={(e) => {
                        const currentInput = e.currentTarget;
                        const nextInput = currentInput.nextElementSibling;
                        handleInputFocus(currentInput, nextInput);
                      }}
                    />
                  ))}
                  <ErrorMessage
                    name="otp"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <button type="submit" className="sign_btn">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </div>
  );
}

export default Otp;
