import React from "react";
import "../App.css";
import Navigationbar from "../Components/navBar/Navigationbar";

function About() {
  return (
    <div className="App about_app">
      <Navigationbar />
      <h1 className="text-center mt-3" style={{ color: "white" }}>
        About
      </h1>
      <p className="about_content p-5 m-0">
        Dynamic Visualizer stands at the forefront of data analysis tools,
        harnessing the power of artificial intelligence to revolutionize how
        data is understood and communicated. This innovative AI tool takes the
        complexity out of data analysis by seamlessly processing your data and
        transforming it into visually compelling graphs and charts. With Dynamic
        Visualizer, you no longer need to be an expert in data visualization to
        make sense of your information. The AI algorithm automatically selects
        the most appropriate graph types based on your data's characteristics,
        ensuring that the insights are both accurate and easily digestible.
        Whether you're a researcher delving into complex datasets, an analyst
        deciphering market trends, or a decision-maker seeking actionable
        insights, this tool simplifies the process. Moreover, Dynamic Visualizer
        can save you time and effort by generating graphs at a pace unmatched by
        manual methods. It empowers users to explore and present their data with
        confidence, enhancing data-driven decision-making across various
        industries. In an era increasingly driven by data, Dynamic Visualizer is
        the bridge between raw information and meaningful understanding, making
        it an indispensable asset for anyone working with data.
      </p>
    </div>
  );
}

export default About;
