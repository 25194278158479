import React, { useState, useEffect } from "react";
import { backendurl } from "../../Utils/BackendURL";
import "./Signinup.css";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { Formik } from "formik"; // Import Formik directly
import * as yup from "yup";
import Container from "react-bootstrap/esm/Container";
import axios from "axios";
import logo from "../../Assets/logo_sign.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function SignUp() {
  const navigate = useNavigate();
  const [showPassword, setShowpassword] = useState(false); // state for show and hide password
  const [reshowPassword, setShowrepassword] = useState(false); // state for show and hide repassword

  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to access user data
      const decodedToken = jwt_decode(token);
      const email = decodedToken.user.email;

      if (email) {
        console.log("Sign In Email:", email);
        navigate("/dashboard");
      }
    }
  }, [navigate]);

  // Show and hide password
  const togglePasswordVisibility = () => {
    setShowpassword(!showPassword);
  };

  const togglerePasswordVisibility = () => {
    setShowrepassword(!reshowPassword);
  };

  const schema = yup.object().shape({
    username: yup.string().required("Name is required"), // Provide error message
    email: yup
      .string("Invalid Email Format")
      .email("Invalid Email Format")
      .required("Email is required"), // Provide error message
    password: yup
      .string()
      .required("Password is required")
      .test("password-strength", "Password is not strong enough", (value) => {
        // Define your password strength criteria here
        // For example, requiring at least 8 characters, including one uppercase letter, one lowercase letter, and one number.
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(value);
      }), // Provide error message
    repassword: yup
      .string()
      .required("Re-enter password is required") // Provide error message
      .oneOf([yup.ref("password"), null], "Passwords must match"), // Check if passwords match
    mobile_number: yup.string().required("Phone number is required"), // Provide error message
  });

  const handleSubmit = (values) => {
    toast("Please wait for a moment", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    axios
      .post(backendurl + "sign-up", values)
      .then((res) => {
        if (res.data.status === "success") {
          // console.log("Registration successful:", res.data);
          navigate(`/verification?email=${values.email}`);
        } else if (res.data.status === "failed") {
          console.log("Registration failed:", res.data);
          alert("Server error please revisit later");
        } else if (res.data.status === "password missmatch") {
          alert("Enter Same password");
        } else if (res.data.status === "User Exist") {
          alert("User Already exist, try different Email and Mobile Number");
        } else if (res.data.status === "Mail Error") {
          alert("Enter Proper mail to Verify");
        } else {
          alert("Error in Signing Up, Please try again");
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  return (
    <div className="forms form_sign_up">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Container className="sign_card">
        <Row>
          <Col className="left_sign">
            <img src={logo} alt="Dynamic Visulizer" className="dynamic_logo" />
            <div className="sign_contents">
              <h1>
                Join Us
                <br />
                <span className="mx-3">in Visulizing</span> <br />{" "}
                <span className="mx-5">your data</span>{" "}
              </h1>
            </div>
          </Col>
          <Col>
            <Card className="glass-container sign_up_glass">
              <h2>Sign Up</h2>
              <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={{
                  username: "",
                  email: "",
                  password: "",
                  repassword: "",
                  mobile_number: "",
                }}
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Col} className="mb-3" controlId="username">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="username" // Make sure this matches the key in Flask
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={!!errors.username && touched.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email && touched.email} // Set isInvalid and check for touched
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password && touched.password} // Set isInvalid and check for touched
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="password_btn"
                      >
                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </button>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="repassword"
                    >
                      <Form.Label>Re-enter Password</Form.Label>
                      <Form.Control
                        type={reshowPassword ? "text" : "password"}
                        name="repassword"
                        value={values.repassword}
                        onChange={handleChange}
                        isInvalid={!!errors.repassword && touched.repassword} // Set isInvalid and check for touched
                      />
                      <button
                        type="button"
                        onClick={togglerePasswordVisibility}
                        className="password_btn"
                      >
                        {reshowPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </button>
                      <Form.Control.Feedback type="invalid">
                        {errors.repassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="mobile_number"
                    >
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="tel"
                        name="mobile_number"
                        value={values.mobile_number}
                        onChange={handleChange}
                        isInvalid={
                          !!errors.mobile_number && touched.mobile_number
                        } // Set isInvalid and check for touched
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.mobile_number}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="text-center">
                      <button type="submit" className="sign_btn">
                        Sign Up
                      </button>
                      <h6 className="mt-3">
                        Already have an Account? <a href="sign-in">Sign In</a>
                      </h6>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SignUp;
