import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Otp from "./pages/auth/Otp";
import Dashboard from "./pages/Dashboard/Dashboard";
import Dynamic from "./pages/Dashboard/Dynamic";
import Userfiles from "./pages/Dashboard/Userfiles";
import Logout from "./pages/auth/Logout";
import Notfountpage from "./pages/Notfountpage";
// import ForgotPass from "./pages/auth/ForgotPass";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/verification" element={<Otp />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dynamic-visulizer" element={<Dynamic />} />
        <Route path="/userfiles" element={<Userfiles />} />
        <Route path="/logout" element={<Logout />} />
        {/* <Route path="/forgot_password" element={<ForgotPass/>} /> */}
        <Route path="*" element={<Notfountpage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
