import React, { useState, useEffect } from "react";
import { backendurl } from "../../Utils/BackendURL";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { Waveform } from "@uiball/loaders";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Dashboard.css";
import Sidebar from "../../Components/sideBar/Sidebar";
import LogoutBtn from "../../Components/logOut/LogoutBtn";

function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to access user data
      const decodedToken = jwt_decode(token);
      const email = decodedToken.user.email;

      // Now you can use the 'email' variable in your component
      // console.log("Email:", email);

      var values = {
        email: email,
      };

      setLoading(true);

      axios
        .post(backendurl + "dashborad", values)
        .then((res) => {
          setLoading(false);
          if (res.data.status === "received") {
            // console.log("received");
            setUsername(res.data.username);
            // console.log("username", username);
          } else {
            alert("Server Error............");
            localStorage.removeItem("token");
            navigate("/");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error:", err);
        });
    } else {
      navigate("/sign-in");
    }
  }, [username, navigate]);

  return (
    <div>
      <style>
        {`.sidenav_home {
              background-color: #B0B5FF;
              border-top-left-radius: 20px;
              border-bottom-left-radius: 20px;
              width:100%;
            }
            `}
      </style>
      <Container fluid="fluid">
        <Row className="dashboard">
          <Col sm={3} className="left_nav">
            <Sidebar />
          </Col>
          <Col sm={9} className="main">
            {loading ? (
              <div className="loader-container">
                <Waveform color="#21254A" />
              </div>
            ) : (
              <>
                <LogoutBtn />
                <h2>
                  <b>Hello {username}</b>
                </h2>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
