import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

function Logout() {
  const navigate = useNavigate()
  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to access user data
      const decodedToken = jwt_decode(token);
      const email = decodedToken.user.email;

      if (email) {
        console.log("Email:", email);
        localStorage.removeItem("token");
        navigate('/')
      }
    }
  }, [navigate]);

  return <div></div>;
}

export default Logout;
