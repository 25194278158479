import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { backendurl } from "../../Utils/BackendURL";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { Waveform } from "@uiball/loaders";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./Dashboard.css";
import Sidebar from "../../Components/sideBar/Sidebar";
import { BiCopy } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import LogoutBtn from "../../Components/logOut/LogoutBtn";
import { ToastContainer, toast } from "react-toastify";

function Userfiles() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [visulizationData, setVisulizationData] = useState([]);

  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to access user data
      const decodedToken = jwt_decode(token);
      const user_id = decodedToken.user.user_id;

      // Now you can use the 'email' variable in your component
      // console.log("User_id:", user_id);

      var values = {
        user: user_id,
      };

      axios
        .post(backendurl + "retrieve", values)
        .then((res) => {
          setLoading(false);
          if (res.data.status === "files retrived") {
            console.log("files retrived");
            setVisulizationData(res.data.visulization_data);
            // console.log(res.data.visulization_data);
          } else if (res.data.status === "No files") {
            console.log("No files");
          } else {
            console.log("not received");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error:", err);
        });
    } else {
      navigate("/sign-in");
    }
  }, [navigate]);

  const copyURLToClipboard = (url) => {
    // Create a temporary input element to copy the URL to the clipboard
    var complete_url = backendurl + "visulizer/" + url;
    const tempInput = document.createElement("input");
    tempInput.value = complete_url;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    toast("URL copied to clipboard: " + complete_url, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const redirectToVisualization = (url) => {
    window.open(url, "_blank"); // Open the URL in a new tab or window
  };

  return (
    <div>
      <style>
        {`.sidenav_files {
              background-color: #B0B5FF;
              border-top-left-radius: 20px;
              border-bottom-left-radius: 20px;
              width:100%;
            }
            `}
      </style>
      <Container fluid="fluid">
        <Row className="dashboard">
          <Col sm={3} className="left_nav">
            <Sidebar />
          </Col>
          <Col sm={9} className="main ">
            {loading ? (
              <div className="loader-container">
                <Waveform color="#21254A" />
              </div>
            ) : (
              <>
                <LogoutBtn />
                <h2>
                  {visulizationData.length > 0
                    ? "Files retrieved"
                    : "No Visulized Files"}
                </h2>
                <div className="file_data">
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                  {Array.isArray(visulizationData)
                    ? visulizationData.map((item) => (
                        <Card key={item.vis_id}>
                          <div className="d-flex flie_btns">
                            <button
                              onClick={() =>
                                copyURLToClipboard(item.visulization_path)
                              }
                            >
                              <BiCopy />
                            </button>
                            <button
                              onClick={() =>
                                redirectToVisualization(
                                  backendurl +
                                    `visulizer/${item.visulization_path}`
                                )
                              }
                            >
                              <AiFillEye />
                            </button>
                          </div>
                          <iframe
                            src={
                              backendurl + `visulizer/${item.visulization_path}`
                            }
                            title="Visualization"
                          ></iframe>
                        </Card>
                      ))
                    : ""}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Userfiles;
