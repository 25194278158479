import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { backendurl } from "../../Utils/BackendURL";
import Container from "react-bootstrap/Container";
import jwt_decode from "jwt-decode";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { Waveform } from "@uiball/loaders";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Dashboard.css";
import Sidebar from "../../Components/sideBar/Sidebar";
import LogoutBtn from "../../Components/logOut/LogoutBtn";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const fileTypes = ["CSV"];

function Dynamic() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to access user data
      // const decodedToken = jwt_decode(token);
      // const email = decodedToken.user.email;
      // Now you can use the 'email' variable in your component
      // console.log("Email:", email);
    } else {
      navigate("/sign-in");
    }
  }, [navigate]);

  const handleChange = (file) => {
    setFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
      const token = localStorage.getItem("token");
      const decodedToken = jwt_decode(token);
      var user_id = decodedToken.user.user_id;
      console.log("user id", user_id);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user", user_id);

      setLoading(true);

      axios
        .post(backendurl + "dynamic_visulization", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.status === "Visualization completed") {
            alert("Success");
            toast("Successfully Visulized goto Visulized Files", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else if (res.data.status === "user error") {
            alert("User Error, please reach out to connect@stackzero.in");
          } else if (res.data.status === "No file provided") {
            alert("Error in File, recheck the file format");
          } else if (res.data.status === "Visualization error") {
            alert(
              "Error in Visualizing your dataset, please reach out to connect@stackzero.in"
            );
          } else {
            console.log("Failure:", res.data.status);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error:", err);
        });
    } else {
      alert("Please select a CSV file and ensure you are logged in.");
    }
  };

  return (
    <div>
      <style>
        {`.sidenav_vis {
              background-color: #B0B5FF;
              border-top-left-radius: 20px;
              border-bottom-left-radius: 20px;
              width: 100%;
            }

            .guZdik{
              height: 450px!important;
              min-width: 100%!important;
            }
            
            .jWkLDY > span, .bpxkGG > span{
                font-size: 25px!important;
            }
            
            .guZdik svg{
                width: 50px;
            }

            @media screen and (max-width: 600px) {

              .guZdik{
                  height: 350px!important;
              }
              
            }
            `}
      </style>
      <Container fluid>
        <Row className="dashboard">
          <Col sm={3} className="left_nav">
            <Sidebar />
          </Col>
          <Col sm={9} className="main">
            {loading ? (
              <div className="loader-container">
                <Waveform color="#21254A" />
              </div>
            ) : (
              <>
                <LogoutBtn />
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                />
                <form onSubmit={handleSubmit}>
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    label="Upload or drop a file right here to Visualize"
                    hoverTitle="Drop right here to Visualize"
                    types={fileTypes}
                  />
                  <div className="text-center mt-3">
                    <button type="submit" className="dynamic_submit">
                      Visualize
                    </button>
                  </div>
                </form>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dynamic;
