import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../Assets/dv-logo-new.png";
import "./Nav.css";
import { Link } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";

const Navigationbar = () => {
  const [isUserLoggedIn, setisUserLoggedIn] = useState(false);
  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to access user data
      const decodedToken = jwt_decode(token);
      const email = decodedToken.user.email;

      if (email) {
        setisUserLoggedIn(true);
        console.log("Email:", email);
      }
    }
  }, []);
  return (
    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={Logo} alt="Dynamic Visualizer" className="nav_logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className="">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            {isUserLoggedIn ? (
              <NavDropdown
                title={
                  <i
                    className="material-icons"
                    style={{ fontSize: "26px"}}
                  >
                    account_circle
                  </i>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/dashboard">
                  {" "}
                  <MdSpaceDashboard /> Dashboard
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/logout">
                  {" "}
                  <AiOutlineLogout /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link as={Link} to="/sign-in">
                Sign In
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
