import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AiFillHome, AiFillPieChart, AiFillFolderOpen } from "react-icons/ai";
import "./Sidebar.css";
import { BiArrowBack } from "react-icons/bi";

function Sidebar() {
  const navigate = useNavigate();
  return (
    <>
      <Nav
        defaultActiveKey="/dashboard"
        className="flex-column sidebar align-content-center"
      >
        <button
          className="back_btn"
          data-tooltip-id="back_toolpit"
          onClick={() => {
            navigate("/");
          }}
        >
          <BiArrowBack />
        </button>
        <ReactTooltip id="back_toolpit" place="bottom" content="Go Back" />
        <h2>Dynamic Visualizer</h2>
        <Nav.Link as={Link} to="/dashboard" className="sidenav_home">
          <AiFillHome /> Home
        </Nav.Link>
        <Nav.Link as={Link} to="/dynamic-visulizer" className="sidenav_vis">
          <AiFillPieChart /> Visulizer
        </Nav.Link>
        <Nav.Link as={Link} to="/userfiles" className="sidenav_files">
          <AiFillFolderOpen /> Visulized Files
        </Nav.Link>
      </Nav>
    </>
  );
}

export default Sidebar;
