import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./logoutbtn.css";

function LogoutBtn() {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex w-100 justify-content-end">
        <button
          className="logout_btn"
          data-tooltip-id="logout_toolpit"
          onClick={() => {
            navigate("/logout");
          }}
        >
          <AiOutlineLogout />
        </button>
        <ReactTooltip id="logout_toolpit" place="bottom" content="Logout" />
      </div>
    </>
  );
}

export default LogoutBtn;
