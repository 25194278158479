import React from "react";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import "../App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import heroImage from "../Assets/dv.png";
import Navigationbar from "../Components/navBar/Navigationbar";

function Home() {
  const navigate = useNavigate();

  const handleSubscribeClick = () => {
    // Redirect to the "Subscribe" page when the button is clicked
    navigate("/sign-up");
  };
  return (
    <div className="App">
      <Navigationbar />
      <Container>
        <Row className="justify-content-center align-items-center mt-5">
          <Col>
            <div className="gradient-text">
              <h1>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter.typeString("Dynamic").start();
                  }}
                />
              </h1>
              <h1 className="ms-5">
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter.pauseFor(1000).typeString("Visualizer").start();
                  }}
                />
              </h1>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <button className="subscribe" onClick={handleSubscribeClick}>
                Subscribe
              </button>
            </div>
          </Col>
          <Col>
            <img
              src={heroImage}
              alt="Dynamic Visualizer"
              className="hero_image"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
