import React from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

function Notfountpage() {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column justify-content-center text-center align-items-center not_found">
      <h1>Oops !</h1>
      <h6>404 page not found</h6>
      <p>
        this page you're looking for might be removed <br /> or is not part of
        website!!
      </p>
      <button
        onClick={() => {
          navigate("/");
        }}
      >
        GO TO Homepage
      </button>
    </div>
  );
}

export default Notfountpage;
