import React, { useState, useEffect } from "react";
import { backendurl } from "../../Utils/BackendURL";
import "./Signinup.css";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { Formik } from "formik"; // Import Formik directly
import * as yup from "yup";
import Container from "react-bootstrap/esm/Container";
import axios from "axios";
import logo from "../../Assets/logo_sign.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignIn() {
  const navigate = useNavigate();
  const [showPassword, setShowpassword] = useState(false); // state for show and hide password

  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the token to access user data
      const decodedToken = jwt_decode(token);
      const email = decodedToken.user.email;

      if (email) {
        console.log("Sign In Email:", email);
        navigate("/dashboard");
      }
    }
  }, [navigate]);

  // Show and hide password
  const togglePasswordVisibility = () => {
    setShowpassword(!showPassword);
  };

  const schema = yup.object().shape({
    email: yup
      .string("Invalid Email Format")
      .email("Invalid Email Format")
      .required("Email is required"), // Provide error message
    password: yup.string().required("Password is required"), // Provide error message
  });

  const handleSubmit = (values) => {
    axios
      .post(backendurl + "sign-in", values)
      .then((res) => {
        if (res.data.status === "correct password") {
          // console.log("Sigin successful:", res.data);
          const token = res.data.token;
          localStorage.setItem("token", token);
          navigate("/dashboard");
        } else if (res.data.status === "wrong password") {
          // console.log("wrong password:", res.data);
          toast("Wrong Password, Recheck your password", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else if (res.data.status === "no user found") {
          // console.log("User name Not found:", res.data);
          toast("User not found,please recheck your email", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else if (res.data.status === "otp required") {
          // console.log("ENter OTP:", res.data);
          toast("OTP verification required", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          navigate(`/verification?email=${values.email}`);
        } else {
          alert("Internal Server Error, Please try again");
          navigate("/");
          // console.log(res.data);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  return (
    <div className="forms form_signin">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Container className="sign_card">
        <Row>
          <Col className="left_sign">
            <img src={logo} alt="Dynamic Visulizer" className="dynamic_logo" />
            <div className="sign_contents">
              <h1>
                Welcome
                <br />
                Visulize your Data
              </h1>
            </div>
          </Col>
          <Col>
            <Card className="glass-container">
              <h3>Sign In</h3>
              <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={{
                  email: "",
                  password: "",
                }}
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="sign_in_form"
                  >
                    <Form.Group as={Col} className="mb-3" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email && touched.email} // Set isInvalid and check for touched
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password && touched.password} // Set isInvalid and check for touched
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="password_btn"
                      >
                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </button>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="text-end">
                      <h6>
                        {/* <a href="forgot_password" className="forgot_password">
                          Forgot Password ?
                        </a> */}
                      </h6>
                    </div>
                    <div className="text-center mb-3">
                      <button type="submit" className="sign_btn">
                        Sign In
                      </button>
                      <h6 className="mt-3">
                        Don't have an Account? <a href="sign-up">Sign Up</a>
                      </h6>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SignIn;
